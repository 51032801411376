

.Controls {
    position:sticky;
    bottom:0;
    display:grid;
    width:100%;
    grid-template-columns: 1fr 1fr;
    grid-gap:1vh 1vw;
    padding:0 1vw 1vh 1vw !important;
    justify-items:center;
    margin:auto;
    flex-grow:0 !important;
    flex-shrink:0 !important;
}

.Controls.stretch {
    justify-items:stretch;
}