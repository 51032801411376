.InfoTop {
  display:flex;
  justify-content:space-between;
  flex-wrap:nowrap;
  padding:1vh 2vw !important;
}

.InfoTop .lifes {
  text-align:right;
  white-space:nowrap;
}
