.App > .PaneBottom {
  display:flex;
  flex-flow: column;
  color:gray;
  height:5%;
}

.PaneBottom .burner {
  position:relative;
  height: 20%;
  left:0;
  right:0;
  background-color:var(--yellow);
  will-change:width;
}

.PaneBottom .ribbon {
  flex-grow:1;
  background-color:var(--mid-blue);
}


.PaneBottom .tunnel {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  pointer-events:none;
  animation: redalert .3s 0s infinite alternate ease-in-out;
  -webkit-animation: redalert .3s 0s infinite alternate ease-in-out;
  display:none;
  will-change:opacity;
}

@keyframes redalert {
  0% {
    box-shadow: inset 0px 0px 0vmin 0vmin red;
  }
  100% {
    box-shadow: inset 0px 0px 1.5vmin 1.5vmin red;
  }
}