

.NicknameInputRow {
    border-top:3px solid var(--yellow);
    border-bottom:3px solid var(--yellow);
}

.NicknameInputRow .inputbox {
    display:grid;
    grid-template-columns: 1fr 1fr;
}

.NicknameInputRow button {
    border-radius: 0 .25rem .25rem 0;
    padding: 0.125rem 0.75rem;
}

.NicknameInputRow input {
    border-radius: .25rem 0 0 .25rem;
    padding: 0.125rem 0.75rem;
    border-right:none !important;
    border:2px solid var(--light-blue)
}