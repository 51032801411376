
.NicknameInputModal .modal-body .fact {
    margin-top:1rem;
}

.NicknameInputModal .modal-dialog[data-status="CORRECT_ANSWER_RANK"] .modal-content {
    border: 1px solid var(--yellow);
    box-shadow: 0px 0px 4vmin 1.5vmin var(--yellow);
}

.NicknameInputModal .GameRank {
    justify-content:center;
    font-size:2em;
    font-weight:bold;
    padding:1em;
}