
.Question .AnswerButton {
    font-size:1.2em;
}


.Question[data-status="WRONG_ANSWER"] .AnswerButton.selected  {
    color: var(--white);
    border-color: var(--red);
    background-color: var(--red);
    opacity:1;
}

.Question[data-status="CORRECT_ANSWER"] .AnswerButton.selected  {
    color: var(--white);
    border-color: var(--green);
    background-color: var(--green);
    opacity:1;
}

.Question[data-status="CORRECT_ANSWER_RANK"] .AnswerButton.selected  {
    color:var(--white);
    border-color: var(--yellow);
    background-color: var(--yellow);
    opacity:1;
}

.Question:not([data-status="QUESTION"]) .AnswerButton {
    opacity:.1;
    pointer-events:none;
}