.table-responsive-sm {
    position:relative;
    overflow-y:auto;
}


.TableFame thead tr {
    position: sticky;
    top: 0;
    background-color: var(--white);
}

.TableFame thead th {
    padding:0;
}

.TableFame thead th span {
    padding:.3rem;
}

.TableFame thead th::after {
    content:"";
    display:block;
    width:100%;
    background-color: var(--light-gray);
    height:1px;
}

.TableFame td {
    vertical-align:middle;
    white-space:nowrap;
}

.TableFame td.position {
    text-align:center;
}

.TableFame td.nickname {
    font-weight:bold;
}