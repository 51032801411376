
html, body, #root, .App {
    height:100% !important;
    width:100% !important;
    overflow:hidden;
    font-family: "Arial CE", Arial, "Verdana CE", Verdana, sans-serif;
    font-size:1.2rem;
}

:root {
    --white:white;
    --dark-blue:#103650;
    --mid-blue:#1A5D8D;
    --light-blue:#2079B9;
    --yellow:#FDCF00;
    --red:#ff0033;
    --green:#32CD32;
    --light-gray:lightgray;
}

.App {
    display:flex;
    flex-direction:column;
}

.App > * {
    flex-grow:0;
    flex-shrink:0;
}

.App > .Hub {
    flex-grow:1;
    flex-shrink:1;
    height:100%;
    width:100%;
    overflow:hidden;
}

.Hub > * {
    display:flex;
    width:100%;
    max-width:100%;
    height:100%;
    max-height:100%;
    flex-flow:column;
}

.App[data-pathname="/"] > .PaneTop {
    display:none;
}

.App .background {
    position:absolute;
    display:block;
    height:100vh;
    width:100vw;
    object-fit:cover;
    z-index:-1;
    opacity:.75;
}

.block {
    margin: auto;
    width:100vh;
    min-width:70vw;
    max-width:100%;
    padding: 3vh 2vw;
}

.modal-content {
    border: 1px solid transparent;
}


.viewport {
    flex-grow:1;
    flex-shrink:1;
    overflow:hidden;
}

.btn {
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    border: 2px solid;
    font-weight:bold;
    color: var(--white);
    border-color: var(--light-blue);
    background-color: var(--light-blue);
    transition: color .4s, border-color .4s, background-color .4s, opacity .4s;
    font-size:1.2rem;
}

.btn svg {
    fill: var(--white);
    transition: fill .4s;
    margin-right:.3em;
    height:1.5em;
    width:1.5em;
}

.btn:hover {
    color: var(--light-blue);
    border-color: var(--light-blue);
    background-color: var(--white);
    transition: color .2s, border-color .2s, background-color .2s, opacity .2s;
}

.btn:hover svg {
    fill: var(--light-blue);
    transition: fill .2s;
}

.btn:focus {
    color: var(--white);
    border-color: var(--dark-blue); 
    background-color: var(--light-blue);
}

.btn:focus svg {
    fill: var(--white);
}