
.QuestionModal .modal-dialog {
    width:80vw;
    min-width:50vw;
    max-width:700px;
}

.QuestionModal .modal-body .fact {
    margin-top:1rem;
}

.QuestionModal .modal-dialog[data-status="CORRECT_ANSWER_FIRST_RANK"] .modal-content,
.QuestionModal .modal-dialog[data-status="CORRECT_ANSWER_RANK"] .modal-content {
    border: 1px solid var(--yellow);
    box-shadow: 0px 0px 4vmin 1.5vmin var(--yellow);
}

.QuestionModal .GameRank {
    justify-content:center;
    flex-wrap:wrap;
    padding:3vh 3vw;
}

.QuestionModal .GameRank img, .QuestionModal .GameRank .name {
    font-size:2em;
    font-weight:bold;
}