

.Home {
  background-color:var(--dark-blue);
  color:white;
}

.Home .logo {
  position:relative;
  overflow:hidden;
 
}

.Home img {
  position:relative;
  height:40vmin;
}

.Home .viewport {
  display:flex;
  text-align:center;
  font-size:1.6rem;
  flex-flow:column;
  justify-content:space-evenly;
  align-items:center;
  padding: 3vh 2vw;
}