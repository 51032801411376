@import 'bootstrap/dist/css/bootstrap.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.list {
  margin: 0;
  padding: 0;
}
.list li {
  list-style: none;
}
.list.s1 li:before {
  font-family: FontAwesome;
  content: "\f00c";
  margin-right: 10px;
}
.list.s2 li:before {
  font-family: FontAwesome;
  content: "\f055";
  margin-right: 10px;
}

.list.s3 {
  display: inline;
  margin: 0;
  padding: 0;
}

.list.s3 li {
  display: inline;
  margin-right: 20px;
}

.list.s3 a {
  font-weight: bold;
}

#owl-logo img {
  opacity: 0.8;
}
#owl-logo img:hover {
  opacity: 1;
}

.spacer-double,
.spacer-single {
  width: 100%;
  display: block;
  clear: both;
}

.spacer-single {
  height: 30px;
}