.Fact {
    padding:3vh 3vw;
    text-align:center;
}

.Fact .caption {
    font-size:2em;
}

.Fact .text {
    font-style:italic;
}