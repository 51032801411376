.App > .PaneTop {
  display:flex;
  align-items:center;
  background-color:var(--dark-blue);
  flex-wrap:nowrap;
  color:white;
  justify-content:space-between;
  height:10%;
  width:100%;
  transition: height 1s ease-in-out;
}

.PaneTop > * {
  flex-grow:1;
  flex-shrink:1;
}

.PaneTop .header {
  position:relative;
  display:flex;
  height:100%;
  width:100%;
  align-items:center;
}

.PaneTop .header img {
  height: 100%;
  pointer-events: none;
  padding:2vh 2vh;
}

.PaneTop .header h1 {
  flex-wrap:nowrap;
  font-size:1.6rem;
  margin:0;
}

.PaneTop > .GameInfo {
  transition:1s;
}



