.InfoBottom {
  display:grid;
  color:var(--dark-blue);
  grid-template-columns: repeat(3, 1fr);
  padding:1vh 2vw !important;
}

.InfoBottom .countdown {
  justify-self:center;
}

.InfoBottom .wrong, .InfoBottom .correct {
  font-size:1.2em;
}

.InfoBottom .wrong {
  justify-self:right;
}

.InfoBottom *[data-value="0"] {
  visibility:hidden;
}

.InfoBottom .correct:before {
  content:"✓";
  color: var(--green);
}

.InfoBottom .wrong:before {
  content:"✗";
  color: var(--red);
}

