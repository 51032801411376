
.GameRank {
    display:flex;
}

.GameRank * {
    margin: 0 .15em;
}

.GameRank img {
    height:1.5em;
}

.GameRank .description {
    text-align:center;
    margin-top:3vh;
    font-style:italic;
}